import { GREY_OUT_TOGGLE, SET_ACTIVE_FACET_TAB, GREY_OUT_CLICKED } from '../Actions/UIState.action';

const defaultState = {
    isGreyedOut: false,
    activeFacetTab: '',
}

export const UIState = (state = defaultState, action) => {
    const { type } = action;
    switch (type) {
        case GREY_OUT_TOGGLE:
            return {
                ...state,
                isGreyedOut: !state.isGreyedOut,
            };
        case SET_ACTIVE_FACET_TAB:
            return {
                ...state,
                activeFacetTab: action.facetTab,
                isGreyedOut: true,
            };
        case GREY_OUT_CLICKED:
            return {
                ...state,
                activeFacetTab: '',
                isGreyedOut: false,
            };
        default:
            return state;
    }
}