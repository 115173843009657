import React, { Component, Fragment } from 'react';
import { get } from '../Services/http';
import { translate } from '../Services/translation';


class QuickOrder extends Component {
    constructor(props) {
        super(props);
        let existing = sessionStorage.getItem('quickOrderState');
        if (existing) {
            this.state = JSON.parse(existing);
        }
        else {
            this.state = {
                items: this.getEmptyList(),
                errormessage: null,
                showItemOutOfStockMessage: false,
                quantityErrorExists: false,
                isLoading: false
            }
        }
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnKeyPressQuantity = this.handleOnKeyPressQuantity.bind(this);
        this.handleHideMsgClick = this.handleHideMsgClick.bind(this);
    }
    setItemsToStorage(state) {
        sessionStorage.setItem("quickOrderState", JSON.stringify(state));
    }
    getEmptyList() {
        let startUp = [];

        for (let index = 0; index < 11; index++) {
            var newItem = {
                id: index,
                articlenumber: "",
                name: null,
                price: null,
                oldprice: null,
                quantity: 0,
                minquantity: 1,
                instock: false,
                dirty: false,
                statusmessage: null,
                loading: false,
                iscampaign: false,
                displayDuplicateMessage: false
            };
            this.handleHideMsgClick(newItem);
            startUp.push(newItem);
        }
        return startUp;
    }

    handleAddClick() {
        this.setState((oldState) => {
            let newItems = oldState.items;
            if (newItems.length == 0) {
                newItems.push({
                    id: 1,
                    articlenumber: "",
                    name: null,
                    price: null,
                    oldprice: null,
                    quantity: 0,
                    minquantity: 1,
                    instock: false,
                    dirty: false,
                    statusmessage: null,
                    loading: false,
                    iscampaign: false,
                    displayDuplicateMessage: false
                })
            } else {
                for (let index = 1; index < 6; index++) {
                    newItems.push(
                        {
                            id: newItems[newItems.length - 1].id + 1,
                            articlenumber: "",
                            name: null,
                            price: null,
                            oldprice: null,
                            quantity: 0,
                            minquantity: 1,
                            instock: false,
                            dirty: false,
                            statusmessage: null,
                            loading: false,
                            iscampaign: false,
                            displayDuplicateMessage: false
                        }
                    )
                }
            }

            this.setItemsToStorage({
                ...oldState,
                items: newItems,
            });
            return {
                ...oldState,
                items: newItems,
            }
        })
    }

    handleRemoveClick(item) {
        this.setState((oldState) => {
            let newItems = oldState.items;
            var index = newItems.indexOf(item);
            newItems.splice(index, 1);

            newItems.forEach(i => {
                if (newItems.filter(x => x.articlenumber.length > 0 && x.articlenumber.toUpperCase() == i.articlenumber.toUpperCase()).length > 1) {
                    i.displayDuplicateMessage = true;
                }
                else {
                    i.displayDuplicateMessage = false;
                }
            });

            this.setItemsToStorage({
                ...oldState,
                items: newItems
            });
            return {
                ...oldState,
                items: newItems
            }
        })
    }

    handleHideMsgClick(item) {
        var qytMsg = document.getElementById(`qty-msg-${item.id}`);
        if (qytMsg)
            qytMsg.classList.add("hide");
    }

    handleOnInputChange(event, product) {
        let foo = event.target;
        this.setState((oldState) => {
            let newItems = oldState.items;
            var index = newItems.find(x => x.id == product.id);
            index.articlenumber = foo.value;
            index.dirty = true;

            this.setItemsToStorage({
                ...oldState,
                items: newItems,
            });

            return {
                ...oldState,
                items: newItems
            }
        })
    }

    handleOnQuantityChange(event, product) {
        let foo = event.target;
        var qytMsg = document.getElementById(`qty-msg-${product.id}`);
        if (parseInt(foo.value) % parseInt(product.minquantity) > 0) {
            if (qytMsg)
                this.setState({ quantityErrorExists: true });
            qytMsg.classList.remove("hide");
        } else {
            this.setState({ quantityErrorExists: false });
            qytMsg.classList.add("hide");
        }
        this.setState((oldState) => {
            let newItems = oldState.items;
            var index = newItems.find(x => x.id == product.id);
            index.quantity = foo.value;
            index.dirty = true;

            this.setItemsToStorage({
                ...oldState,
                items: newItems,
            });

            return {
                ...oldState,
                items: newItems
            }
        })
    }

    handleOnKeyPress(event, product) {
        if (event.key == 'Enter') {
            this.handleOnBlur(product);

            var nextInput = document.getElementById(`p-input-qty-${product.id}`);
            if (nextInput)
                nextInput.focus();
        }
    }

    handleOnKeyPressQuantity(event, product) {
        if (event.key == 'Enter') {
            this.handleOnBlur(product);

            var nextInput = document.getElementById(`p-input-${product.id + 1}`);
            if (nextInput)
                nextInput.focus();
        }
    }

    handleOnBlur(product) {
        if (!product.articlenumber)
            return;

        this.setState((oldState) => {
            let newItems = oldState.items;
            product.displayDuplicateMessage = false;
            if (product.dirty && this.state.items.filter(x => x.articlenumber.toUpperCase() == product.articlenumber.toUpperCase()).length == 1) {
                var index = newItems.find(x => x.id == product.id);
                index.loading = true;
                this.getArticleData(product);
            }
            else if (product.dirty) {
                product.displayDuplicateMessage = true;
            }
            this.setItemsToStorage({
                ...oldState,
                items: newItems,
            });
            return {
                ...oldState,
                items: newItems
            }
        });
    }

    handleFetchSuccessResponse(result, product) {
        if (result.success) {
            this.setState((oldState) => {
                let newItems = oldState.items;
                var rowItem = newItems.find(x => x.articlenumber.toUpperCase() == result.product.ArticleNumber);
                rowItem.name = result.product.Name;
                rowItem.price = result.product.Price;
                rowItem.instock = result.product.IsInStock;
                rowItem.statusmessage = result.product.StatusMessage;
                rowItem.quantity = result.product.MinQuantity || 1;
                rowItem.dirty = false;
                rowItem.loading = false;
                rowItem.minquantity = result.product.MinQuantity || 1;
                rowItem.iscampaign = result.product.IsCampaign;
                rowItem.oldprice = result.product.OldPrice;

                this.setItemsToStorage({
                    ...oldState,
                    items: newItems,
                    errormessage: null,
                    showItemOutOfStockMessage: rowItem.instock ? false : true
                });

                return {
                    ...oldState,
                    items: newItems,
                    errormessage: null,
                    showItemOutOfStockMessage: rowItem.instock ? false : true
                }
            })
        }
        else {
            this.setState((oldState) => {
                let newItems = oldState.items;
                newItems.forEach(element => {
                    element.loading = false;
                });
                var rowItem = newItems.find(x => x.articlenumber.toUpperCase() == product.articlenumber);
                if (rowItem) {
                    rowItem.articlenumber = "";
                    rowItem.price = null;
                    rowItem.oldprice = null;
                    rowItem.iscampaign = false;
                    rowItem.loading = false;
                    rowItem.name = null;
                    rowItem.quantity = 1;
                    rowItem.minquantity = 1;
                    rowItem.minPurchase = 1;
                    rowItem.maxPurchase = 999;

                }
                this.setItemsToStorage({
                    ...oldState,
                    items: newItems,
                    errormessage: result.message
                });

                return {
                    ...oldState,
                    items: newItems,
                    errormessage: result.message
                }
            })
        }

    }

    handleFetchErrorResponse(error, product) {
        this.setState((oldState) => {
            let newItems = oldState.items;
            var index = newItems.find(x => x.id == product.id);
            index.loading = false;
            this.setItemsToStorage({
                ...oldState,
                items: newItems,
                errormessage: "Something went wrong",
                showItemOutOfStockMessage: false
            });
            return {
                ...oldState,
                items: newItems,
                errormessage: "Something went wrong",
                showItemOutOfStockMessage: false
            }
        })
    }

    handleSubmit(e) {
        let products = this.state.items.filter(x => x.instock && x.quantity > 0 && x.quantity % x.minquantity == 0);
        this.addMultipleProductsToCart(products, e.target.id, Date.now());
        this.setState((oldState) => {
            this.setItemsToStorage({
                ...oldState,
                items: this.getEmptyList(),
                showItemOutOfStockMessage: false
            });
            return {
                ...oldState,
                items: this.getEmptyList(),
                showItemOutOfStockMessage: false
            }
        })
    }
    addMultipleProductsToCart(products, nodeIdToShowNotification) {
        if (products.length > 0) {
            this.props.addMultipleToCart(products, nodeIdToShowNotification)
        }
    }

    addProductToCart(product) {
        if (product.articlenumber) {
            this.props.addToCart({
                articleNumber: product.articlenumber,
                quantity: product.quantity,
            });
        }
    }

    getArticleData(product) {
        get(`/api/quickorder?query=${product.articlenumber}`)
            .then(response => response.json())
            .then(result => this.handleFetchSuccessResponse(result, product))
            .catch(ex => this.handleFetchErrorResponse(ex, product));
    }

    getDuplicateMsg(product) {
        if (product.displayDuplicateMessage) {
            let msg = translate("quickorder.duplicate-item-message");
            return msg += ` ${product.articlenumber}`;
            // var allDuplicates = this.state.items.filter(x => this.state.items.indexOf(c => c.articlenumber == x.articlenumber))

            // return msg+=` ${allDuplicates.filter(x => x.articlenumber.length > 0).map(x => x.articlenumber)[0]}`;
        }
        return "";
    }

    tableRow(product) {
        return (
            <div className="product-row small-12" key={product.id}>
                <div className="article small-12 row">
                    <div className="articlenumber small-2">
                        <input style={{ textTransform: 'uppercase' }} type="text" id={`p-input-` + product.id} className="articlenumber" value={product.articlenumber} onChange={event => this.handleOnInputChange(event, product)} onKeyPress={event => this.handleOnKeyPress(event, product)} onBlur={event => this.handleOnBlur(product)}></input>
                        <i className={product.loading ? "fal fa-spinner fa-spin" : "hidden"}></i>
                    </div>
                    <div className="name small-3">{product.name}</div>
                    <div className="price small-2">{product.price} <span className="oldprice">{product.oldprice}</span>{product.iscampaign ? <div className="campaign-sticker">{translate("project.campaign-sticker")}</div> : null}</div>
                    <div className="min-quantity small-1">{product.minquantity}</div>
                    <div className="quantity small-2">
                        <input type="number" id={`p-input-qty-` + product.id} min="0" step={product.minquantity} className="quantity" value={product.quantity} onChange={e => this.handleOnQuantityChange(event, product)} onKeyPress={event => this.handleOnKeyPressQuantity(event, product)}></input>
                        <span className="quantity-msg label warning hide" id={`qty-msg-` + product.id}>{translate("quickorder.item-min-quantity-msg")} {product.minquantity || 1}</span>
                    </div>
                    <div className="stock small-1">{product.statusmessage ? <span className={product.instock ? "label success" : "label warning"}>{product.statusmessage}</span> : null}</div>
                    <div className="small-1"><button type="button" className="alert button" tabIndex="-1" onClick={event => this.handleRemoveClick(product)}><i className="fal fa-trash-alt"></i></button></div>
                </div>
                <div className="msg-area small-12 row">
                    {product.displayDuplicateMessage ? <span className="stock-message label warning align-right"><i className="fal fa-exclamation-circle"></i><b>{this.getDuplicateMsg(product)}</b></span> : null}
                </div>
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <div className="quickorder-container large-12 medium-12 small-12 columns row">
                    {this.state.errormessage ? <span className="label warning">{this.state.errormessage}</span> : null}
                    {this.state.showItemOutOfStockMessage ? <span className="stock-message label warning align-right"><i className="fal fa-exclamation-circle"></i><b>{translate("quickorder.item-out-of-stock-message")}</b></span> : null}
                    {/* {this.state.displayDuplicateMessage ? <span className="stock-message label warning align-right"><i className="fal fa-exclamation-circle"></i><b>{this.getDuplicateMsg()}</b></span> : null} */}
                    <br />
                    <div className="quickorder-container__table large-12 medium-12 small-12">
                        <div className="quickorder-container__table-header large-12 medium-12 small-12">
                            <div className="articlenumber small-2 header">{translate("quickorder.articlenumber")}</div>
                            <div className="name small-3 header">{translate("quickorder.name")}</div>
                            <div className="price small-2 header">{translate("checkout.cart.header.price")}</div>
                            <div className="quantity small-1 header">{translate("quickorder.header.quantity-in-pack")}</div>
                            <div className="quantity small-2 header">{translate("quickorder.header.quantity")}</div>
                            <div className="stock small-1 header"></div>
                            <div className="small-1 header"></div>
                        </div>
                        {this.state.items.map(item => this.tableRow(item))}
                    </div>
                    <div className="buttons">
                        <button type="button" className="success button" onClick={this.handleAddClick}>{translate("quickorder.add-row")}</button>
                        <button type="submit" id="quick-add-buy-button" className="button add-to-cart" disabled={this.state.quantityErrorExists || this.props.isLoading} onClick={this.handleSubmit}>{translate("quickorder.add-to-cart")}&nbsp;&nbsp;<i className={this.props.isLoading ? "fal fa-spinner fa-spin" : "hidden"}></i></button>
                        <span style={{ marginLeft: "5px" }}>{this.state.quantityErrorExists ? translate('quickorder.formerrorsexist') : ""}</span>
                        <span style={{ marginLeft: "5px" }}>{this.props.isLoading ? translate('quickorder.addingtocartinfo') : ""}</span>
                    </div>
                </div>
            </Fragment>
        );
    }
}


export default QuickOrder;