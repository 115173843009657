import React, { Fragment, Component } from 'react';
import { translate } from '../Services/translation';

class GreyOut extends Component {
    constructor(props) {
        super(props);
        this.outsideClicked = this.outsideClicked.bind(this);
    }

    componentDidMount() {
        
    }

    outsideClicked() {
        this.props.outsideClicked();
    }
    render() {
        const { isGreyedOut} = this.props;
        return (
            <Fragment>
                <div onClick={this.outsideClicked} className={isGreyedOut ? "greyOutShow" : "greyOutHide"}></div>
            </Fragment>
        )
    }
}
export default GreyOut;
