import { get, post, put } from '../Services/http';
import { catchError } from './Error.action';
import { show as showNotification, hide as hideNotification } from './Notification.action';
import { translate } from '../Services/translation';
import { set } from 'lodash';
import { error } from '../Reducers/Error.reducer';
import { useEffect } from 'react';

export const CART_LOAD = 'CART_LOAD';
export const CART_LOAD_ERROR = 'CART_LOAD_ERROR';
export const CART_RECEIVE = 'CART_RECEIVE';
export const CART_SHOW_INFO = 'CART_SHOW_INFO';
export const CART_HIDE = 'CART_HIDE';
export const QUICK_ORDER_ISLOADING = 'QUICK_ORDER_ISLOADING';
export const CART_DISABLE_BUY_BUTTON = 'CART_DISABLE_BUY_BUTTON';

export const load = () => (dispatch, getState) => {
    return get('/api/cart')
        .then(response => response.json())
        .then(cart => dispatch(receive(cart)))
        .catch(ex => dispatch(catchError(ex, error => loadError(error))))
        ;
}

export const loadError = error => ({
    type: CART_LOAD_ERROR,
    payload: {
        error,
    }
})

export const setIsLoading = (isLoading) => ({
    type: QUICK_ORDER_ISLOADING,
    payload: {
        isLoading,
    }
})

export const receive = cart => ({
    type: CART_RECEIVE,
    payload: cart,
})

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().cart.showInfo));
}

const show = visible => ({
    type: CART_SHOW_INFO,
    payload: {
        showInfo: visible,
    }
})

const disableBuyButton = (articleNumber) => {
    document.querySelectorAll(".buy-button[data-article-number='" + articleNumber + "']").forEach(b => b.setAttribute("disabled", "disabled"))
}


export const add = ({ articleNumber, minText = null, maxText = null, quantity = 1, nodeIdToShowNotification = null, notificationMessage = null, hash = '', packageQuantity = 1, minQuant = 1, maxQuant = 1 }) => {
    return (dispatch) => {
        if (!quantity || isNaN(quantity) || parseInt(quantity) <= 0) {
            return;
        }
        return post('/api/cart/add', { articleNumber, quantity: parseInt(quantity), minimumQuantity: parseInt(packageQuantity) })
            .then(response => response.json())
            .then(cart => {
                dispatch(receive(cart))
                nodeIdToShowNotification && dispatch(showNotification(nodeIdToShowNotification, notificationMessage, hash))

                var recentlyAddedItem = cart.orderRows.filter(x => x.articleNumber == articleNumber);
                var recentlyAddedItemQuant = (recentlyAddedItem[0].quantity / packageQuantity);

                if (recentlyAddedItemQuant >= maxQuant) {
                    disableBuyButton(articleNumber)
                }
            })
            .catch(ex => {
                dispatch(catchError(ex, error => {
                    loadError(error);
                    if (error.message === "MaxQuantityReached") {
                        disableBuyButton(articleNumber);
                    } else if (error.message === "MinQuantityNotReached") {
                        dispatch(showNotification(nodeIdToShowNotification, translate('buybutton.mintext'), hash));
                        document.querySelector(".product-detail__quantity-input").focus();
                    } else if (error.message === "MaxQuantityExceeded") {
                        dispatch(showNotification(nodeIdToShowNotification, translate('buybutton.maxtext'), hash));
                        document.querySelector(".product-detail__quantity-input").focus();
                    }
                }))
            })
    }
}

export const addMultiple = (products, nodeIdToShowNotification = null, hash = '') => {
    return (dispatch) => {
        if (products.filter(p => !p.quantity || isNaN(p.quantity) || parseInt(p.quantity) <= 0).length > 0) {
            return;
        }
        try {
            dispatch(setIsLoading(true));
            return post('/api/cart/addMultiple', products.map(product => {
                return {
                    articleNumber: product.articlenumber,
                    quantity: parseInt(product.quantity)
                }
            }))
                .then(response => response.json())
                .then(cart => {
                    dispatch(receive(cart));
                    dispatch(setIsLoading(false));
                })
                .catch(ex => dispatch(catchError(ex, error => {
                    loadError(error);
                    if (error.message === "MaxQuantityExceeded") {
                        dispatch(showNotification(nodeIdToShowNotification, translate('buybutton.maxtext'), hash))
                        dispatch(setIsLoading(false));
                        setTimeout(() => {
                            dispatch(hideNotification(nodeIdToShowNotification, translate('buybutton.maxtext'), hash))
                        }, 5000)
                    }
                })));
        }
        catch (ex) {
            return dispatch(catchError(ex, error => loadError(error)));
        }
    }
}

export const reorder = (orderid, nodeIdToShowNotification = 'miniCart', notificationMessage = translate('tooltip.addedtocart'), hash = '') => {
    if (orderid) {
        return (dispatch) => {
            return post('/api/cart/reorder', { orderid })
                .then(response => response.json())
                .then(cart => {
                    dispatch(receive(cart));
                    dispatch(showNotification(nodeIdToShowNotification, notificationMessage, hash))
                })
        }
    }
}

export const update = (rowSystemId, quantity) => (dispatch) => {
    return put(`/api/cart/update`, { rowSystemId, quantity })
        .then(response => response.json())
        .then(cart => dispatch(receive(cart)))
        .catch(ex => dispatch(catchError(ex, error => { loadError(error); })));
}