import { QUICK_ORDER_ISLOADING } from '../Actions/Cart.action';

const DEFAULT_STATE = { 
    isLoading: false 
};

export const quickOrder = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case QUICK_ORDER_ISLOADING:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}