import React, { Component } from 'react';
import { connect } from 'react-redux';
import GreyOut from '../Components/GreyOut';
import { outsideClicked } from '../Actions/UIState.action';

class UIState extends Component {
    render() {
        return <GreyOut {...this.props} />;
    }
}
const mapStateToProps = ({ facetedSearch: { facetFilters, navigationTheme }, UIState}) => {
    return {
        isGreyedOut: UIState.isGreyedOut,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        outsideClicked: () => dispatch(outsideClicked()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UIState);